import React from "react";
import "./footer.scss";
import { Language } from "../../../utils/Language";

export default function Footer() {
    return (
        <footer className="footer">
            <div>{Language.footer}</div>
            <div>{Language.footer_copy}</div>
            <div className="footer_designer">
                Designed by:{" "}
                <a
                    href="https://www.facebook.com/WebDeveloperKrystianBudzisz"
                    target="__blank"
                >
                    K.B
                </a>
            </div>
        </footer>
    );
}
