import React, { useRef } from "react";

import "./Contact.scss";
import Contact_Image from "../../../Assets/Images/views/Contact_Image.jpg";
import facebook from "../../../Assets/Images/facebook.svg";
import instagram from "../../../Assets/Images/instagram.svg";
import tiktok from "../../../Assets/Images/tiktok.svg";
import { FaLocationDot } from "react-icons/fa6";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Button, CircularProgress, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { Icon } from "leaflet";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import { Language } from "../../../utils/Language";

export default function Contact() {
    const form = useRef(null);

    const [loading, setLoading] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        setLoading(true);

        emailjs
            .sendForm(
                "service_924fatp",
                "template_jk8gdrb",
                form.current,
                "VFma7dJQW-5DZtpuD"
            )
            .then(
                (result) => {
                    e.target.reset();
                    setLoading(false);
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    const markerIcon = new Icon({
        iconUrl: "https://atrustfall.com/markerIcon.png",
        shadowUrl: "",
        iconSize: "35",
        shadowSize: "0",
    });

    return (
        <div className="Contact_Container">
            <div className="Contact_Image">
                <img src={Contact_Image} alt="" />
            </div>
            <div className="Contact_Data">
                <div className="Contact_Data_leftSide">
                    <div className="Contact_element">
                        <div className="Contact_element__image location">
                            <FaLocationDot />
                        </div>
                        <div className="Contact_element__text">
                            <div>32-600 Oświęcim</div>
                        </div>
                    </div>
                    <div className="Contact_element">
                        <div className="Contact_element__image phone">
                            <BsFillTelephoneFill />
                        </div>
                        <div className="Contact_element__text">
                            <div>Natalia Kurek</div>
                            <div>+48 502 969 714</div>
                        </div>
                    </div>
                    <div className="Contact_element">
                        <div className="Contact_element__image mail">
                            <MdEmail />
                        </div>
                        <div className="Contact_element__text">
                            atrustfallfci@gmail.com
                        </div>
                    </div>
                    <div className="Contact_element">
                        <div className="Contact_element__social">
                            <div className="Contact_element__social--img">
                                <a
                                    href="https://www.facebook.com/natalia.kurek.54"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={facebook} alt="facebook logo" />
                                </a>
                            </div>
                            <div className="Contact_element__social--img">
                                <a
                                    href="https://www.instagram.com/a_trustfall/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={instagram} alt="instagram logo" />
                                </a>
                            </div>
                            <div className="Contact_element__social--img">
                                <a
                                    href="https://www.tiktok.com/@a_trustfall"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={tiktok} alt="tiktok logo" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Contact_Data_rightSide">
                    <div className="Contact_Data_rightSide__map">
                        <MapContainer
                            attributionControl={false}
                            center={[50.02631486080654, 19.221977881194462]}
                            zoom={13}
                            scrollWheelZoom={true}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />

                            <Marker
                                position={[
                                    50.02631486080654, 19.221977881194462,
                                ]}
                                icon={markerIcon}
                            ></Marker>
                        </MapContainer>
                    </div>
                </div>
            </div>
            <form ref={form} onSubmit={sendEmail}>
                <div className="Contact_Form">
                    <div className="Contact_Form_Title">
                        {Language.contact_withus}
                    </div>
                    <div className="Contact_Form_Data">
                        <div className="Contact_Form_Data--element">
                            <TextField
                                fullWidth
                                required
                                label={Language.contact_form_name}
                                name="from_name"
                            />
                        </div>
                        <div className="Contact_Form_Data--element">
                            <TextField
                                type="email"
                                fullWidth
                                required
                                label={Language.contact_form_email}
                                name="from_email"
                            />
                        </div>
                        <div className="Contact_Form_Data--element">
                            <TextField
                                fullWidth
                                required
                                label={Language.contact_form_subject}
                                name="subject"
                            />
                        </div>
                        <div className="Contact_Form_Data--element">
                            <TextField
                                fullWidth
                                required
                                multiline
                                rows={6}
                                maxRows={24}
                                label={Language.contact_form_message}
                                name="message"
                            />
                        </div>
                        <div className="Contact_Form_Data--element submit_container">
                            <Button
                                className="submit_container--button"
                                variant="contained"
                                style={{
                                    backgroundColor: "rgba(46, 134, 206, 1)",
                                }}
                                type="submit"
                            >
                                {loading ? (
                                    <CircularProgress color="error" />
                                ) : (
                                    Language.send
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
