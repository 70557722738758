import React from "react";

import "./Aboutus.scss";
import MainImage from "../../../Assets/Images/views/Aboutus/mainImage.jpg";
import { BsQuote } from "react-icons/bs";

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

import axios from "axios";
import cookies from "react-cookies";
import { BiSolidQuoteLeft, BiSolidQuoteRight } from "react-icons/bi";
import { Language } from "../../../utils/Language";
import Mia2 from "../../../Assets/Images/views/MainPage/Mia2.jpg";

export default class MainPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            items: [],
        };
    }

    async componentDidMount() {
        axios
            .get(`https://api.atrustfall.com/aboutus?_sort=id&_order=ASC`)
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { items, isLoading, error } = this.state;
        const lang = cookies.load("language");

        if (error) return <span>Error</span>;
        else if (!isLoading)
            return (
                <div className="flex w-screen h-full justify-center items-center">
                    Loading
                </div>
            );
        else {
            return (
                <div className="Aboutus">
                    <div className="AboutUs_MainImage">
                        <img src={MainImage} alt="Main Page" />
                    </div>

                    <div className="Aboutus--title">{Language.aboutUs}!</div>

                    {/* API */}
                    <div className="Aboutus_Data">
                        {items.length > 0 ? (
                            items
                                .filter((f) => f.type === "normal")
                                .map((item) => (
                                    <div className="Aboutus_Data--element">
                                        <div className="Aboutus_Data--element__mainImg">
                                            {item.images.length > 0
                                                ? item.images
                                                      .filter(
                                                          (fi) =>
                                                              fi.field ===
                                                              "mainImg"
                                                      )
                                                      .map((img) => (
                                                          <img
                                                              src={img.path}
                                                              alt=""
                                                          />
                                                      ))
                                                : ""}
                                        </div>
                                        <div className="Aboutus_Data--element__data">
                                            <div className="Aboutus_Data--title">
                                                {lang === "pl"
                                                    ? item.title_pl
                                                    : item.title_en}
                                            </div>
                                            <div
                                                className="Aboutus_Data--Text"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        lang === "pl"
                                                            ? item.description_pl
                                                            : item.description_en,
                                                }}
                                            ></div>

                                            {item.images.length > 0 ? (
                                                <div className="Aboutus_Data--Gallery">
                                                    <SimpleReactLightbox>
                                                        <SRLWrapper
                                                            options={{
                                                                buttons: {
                                                                    showDownloadButton: false,
                                                                },
                                                            }}
                                                        >
                                                            {item.images
                                                                .filter(
                                                                    (fi) =>
                                                                        fi.field ===
                                                                        "gallery"
                                                                )
                                                                .sort(function (
                                                                    a,
                                                                    b
                                                                ) {
                                                                    return (
                                                                        a.sortID -
                                                                        b.sortID
                                                                    );
                                                                })
                                                                .map(
                                                                    (
                                                                        galleryItem
                                                                    ) => (
                                                                        <img
                                                                            src={
                                                                                galleryItem.path
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    )
                                                                )}
                                                        </SRLWrapper>
                                                    </SimpleReactLightbox>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <div>null</div>
                        )}

                        <div className="AboutUs_MainImage">
                            <img src={Mia2} alt="Main Page" />
                        </div>

                        <div className="MainPage_Data--Quote">
                            {items.length > 0 ? (
                                items
                                    .filter((f) => f.type === "quote")
                                    .map((item) => (
                                        <div className="MainPage_Data--Quote_element">
                                            {item.images.length > 0 ? (
                                                <div className="MainPage-Quote_Image">
                                                    <img
                                                        src={
                                                            item.images[0].path
                                                        }
                                                        alt={""}
                                                    />
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            <div className="MainPage-Quote_Data_Container">
                                                <div className="MainPage-Quote_Data_Container__descCon">
                                                    <div className="MainPage-Quote_Data_Container__img">
                                                        <BiSolidQuoteLeft />
                                                    </div>
                                                    <div className="MainPage-Quote_Data_Container__imgEnd">
                                                        <BiSolidQuoteRight />
                                                    </div>
                                                    <div
                                                        className="MainPage-Quote_Data_Container__descCon--desc"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                lang === "pl"
                                                                    ? item.description_pl
                                                                    : item.description_en,
                                                        }}
                                                    ></div>
                                                    <div className="MainPage-Quote_Data_Container__descCon--author">
                                                        {item.title_pl}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            ) : (
                                <div>null</div>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    }
}
