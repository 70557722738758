import axios from "axios";
import React from "react";
import { Language } from "../../../utils/Language";

import "./Dogs.scss";
import { Link } from "react-router-dom/cjs/react-router-dom";

export default class Dogs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            items: [],
        };
    }

    async componentDidMount() {
        axios
            .get(`https://api.atrustfall.com/dogs`)
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { items, isLoading, error } = this.state;

        if (error) return <span>Error</span>;
        else if (!isLoading) return <span>Loading</span>;
        else
            return (
                <div className="Dogs_Container">
                    <div className="Dogs_Container--title">
                        {Language.ourDogs}:
                    </div>
                    <div className="Dogs_Container_List">
                        {items.length > 0
                            ? items.map((item) => (
                                  <Link to={`/dogs/${item.id}`}>
                                      <div className="Dogs_Container_List_Element">
                                          <div className="Dogs_Container_List_Element--mainImg">
                                              {item.images.filter(
                                                  (f) => f.field === "mainImg"
                                              ).length > 0
                                                  ? item.images
                                                        .filter(
                                                            (f) =>
                                                                f.field ===
                                                                "mainImg"
                                                        )
                                                        .map((img) => (
                                                            <img
                                                                src={img.path}
                                                                alt=""
                                                            />
                                                        ))
                                                  : ""}
                                          </div>
                                          <div className="Dogs_Container_List_Element--data">
                                              <div className="Dogs_Container_List_Element--data-name">
                                                  {item.pedigreename}
                                              </div>
                                              <div className="Dogs_Container_List_Element--data-parents">
                                                  ({item.mothername} x{" "}
                                                  {item.fathername})
                                              </div>
                                          </div>
                                      </div>
                                  </Link>
                              ))
                            : "Aktualnie nie mamy żadnego psa :("}
                    </div>
                </div>
            );
    }
}
