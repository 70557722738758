import React, { Component } from "react";

import "./LittersDetails.scss";
import axios from "axios";

import cookies from "react-cookies";
import { Language } from "../../../utils/Language";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import PuppyGallery from "../../Other/puppyGallery/puppyGallery";

export default class LittersDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            items: [],
            itemsPuppies: [],
            puppiesLoading: false,
        };
    }

    async getData() {
        await axios
            .get(
                `https://api.atrustfall.com/litters/${this.props.match.params.id}`
            )
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    async getPuppies() {
        await axios
            .get(`https://api.atrustfall.com/puppies`)
            .then((res) => {
                this.setState({
                    itemsPuppies: res.data,
                    puppiesLoading: true,
                });
            })
            .catch((error) => {
                this.setState({
                    error: error.message,
                });
            });
    }

    async componentDidMount() {
        await this.getData();
        await this.getPuppies();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            await axios
                .get(
                    "https://api.atrustfall.com/litters/" +
                        this.props.match.params.id
                )
                .then((res) => {
                    this.setState({
                        isLoading: true,
                        items: res.data,
                    });
                })
                .catch((error) => {
                    this.setState({
                        isLoading: true,
                        error: error.message,
                    });
                });
        }
    }

    render() {
        const { items, itemsPuppies, puppiesLoading, isLoading, error } =
            this.state;
        const lang = cookies.load("language");

        if (error) return <div>Error</div>;
        else if (!isLoading) return <div>Loading</div>;
        else if (!puppiesLoading) return <div>Loading</div>;
        else {
            return (
                <div className="LittersDetails_Container">
                    <div className="LittersDetails_Container--title">
                        {items.name}
                    </div>
                    <div className="LittersDetails_Container--data">
                        <div className="litterDesc">
                            <div
                                className="litterDesc-description"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        lang === "pl"
                                            ? items.description_pl
                                            : items.description_en,
                                }}
                            ></div>
                        </div>
                        <div className="litterMainimg">
                            {items.images.length > 0 ? (
                                <SimpleReactLightbox>
                                    <SRLWrapper
                                        options={{
                                            buttons: {
                                                showDownloadButton: false,
                                            },
                                        }}
                                    >
                                        <img
                                            src={items.images[0].path}
                                            alt=""
                                        />
                                    </SRLWrapper>
                                </SimpleReactLightbox>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    {items.images.filter((f) => f.field === "panorama").length >
                    0 ? (
                        <div className="litterDetails_panorama">
                            {items.images
                                .filter((f) => f.field === "panorama")
                                .map((im) => (
                                    <img src={im.path} alt="" />
                                ))}
                        </div>
                    ) : (
                        ""
                    )}

                    {itemsPuppies.filter((f) => f.litter == items.id).length >
                        0 && puppiesLoading ? (
                        <div className="puppies">
                            <div className="puppies_title">
                                {Language.puppies}
                            </div>
                            {itemsPuppies
                                .filter((f) => f.litter == items.id)
                                .map((puppy, index) => (
                                    <div className="puppyContainer" key={index}>
                                        <div className="puppyContainer__mainImg">
                                            <SimpleReactLightbox>
                                                <SRLWrapper>
                                                    <img
                                                        src={
                                                            puppy.images.filter(
                                                                (f) =>
                                                                    f.field ===
                                                                    "mainImg"
                                                            )[0].path
                                                        }
                                                        alt=""
                                                    />
                                                </SRLWrapper>
                                            </SimpleReactLightbox>
                                        </div>
                                        <div
                                            className="puppyContainer__desc"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    lang === "pl"
                                                        ? puppy.description_pl
                                                        : puppy.description_en,
                                            }}
                                        ></div>
                                        {puppy.images.filter(
                                            (f) => f.field === "gallery"
                                        ).length > 0 ? (
                                            <PuppyGallery puppy={puppy} />
                                        ) : (
                                            ""
                                        )}
                                        <div className="puppyContainer__border"></div>
                                    </div>
                                ))}
                        </div>
                    ) : (
                        ""
                    )}

                    {items.images.filter((f) => f.field === "gallery").length >
                    0 ? (
                        <div className="LittersDetails_Container--gallery">
                            <SimpleReactLightbox>
                                <SRLWrapper
                                    options={{
                                        buttons: { showDownloadButton: false },
                                    }}
                                >
                                    {items.images
                                        .filter((f) => f.field === "gallery")
                                        .sort(function (a, b) {
                                            return a.sortID - b.sortID;
                                        })
                                        .map((image) => (
                                            <img src={image.path} alt="" />
                                        ))}
                                </SRLWrapper>
                            </SimpleReactLightbox>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            );
        }
    }
}
