import React from "react";
import { BsFillArrowUpCircleFill } from "react-icons/bs";

import "./GoTopButton.scss";
import { useState } from "react";
import { useEffect } from "react";

export default function GoTopButton() {
    const [posY, setposY] = useState(window.scrollY);

    useEffect(() => {
        const scrollGet = () => setposY(window.scrollY);

        window.addEventListener("scroll", scrollGet);
        return () => window.removeEventListener("scroll", scrollGet);
    }, []);

    return posY > 250 ? (
        <div
            className="goTopButton"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
            <BsFillArrowUpCircleFill />
        </div>
    ) : (
        ""
    );
}
