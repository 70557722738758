import React from "react";

import "./MainPage.scss";
import MainImage from "../../../Assets/Images/views/MainPage_Slider/mainPage1.jpg";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { BiSolidQuoteLeft, BiSolidQuoteRight } from "react-icons/bi";

import ZKWP_logo from "../../../Assets/Images/views/MainPage/ZKWP_logo.jpg";
import FCI_logo from "../../../Assets/Images/views/MainPage/FCI_logo.jpg";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Mia from "../../../Assets/Images/views/MainPage/Mia.jpg";

import axios from "axios";
import cookies from "react-cookies";
import { Language } from "../../../utils/Language";

export default class MainPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            items: [],
        };
    }

    async componentDidMount() {
        axios
            .get(`https://api.atrustfall.com/mainpage?_sort=id&_order=DESC`)
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const slideLeft = () => {
            var slider = document.getElementById("slider");
            slider.scrollLeft =
                slider.scrollLeft - document.body.clientWidth / 2;
        };

        const slideRight = () => {
            var slider = document.getElementById("slider");
            slider.scrollLeft =
                slider.scrollLeft + document.body.clientWidth / 2;
        };

        const lang = cookies.load("language");

        const { items, isLoading, error } = this.state;

        if (error) return <span>Error</span>;
        else if (!isLoading)
            return (
                <div className="flex w-screen h-full justify-center items-center">
                    Loading
                </div>
            );
        else {
            return (
                <div className="MainPage">
                    <div className="MainPage_MainImage">
                        <img src={MainImage} alt="Main Page" />
                    </div>

                    {/* API */}
                    <div className="MainPage_Data">
                        <div className="title">{Language.welcome_title}</div>

                        <div className="sub-title">
                            {Language.welcome_subtitle}
                        </div>

                        {items.length > 0 ? (
                            items
                                .filter((f) => f.type === "normal")
                                .map((item) => (
                                    <>
                                        <div
                                            className="MainPage_Data--Text"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    lang === "pl"
                                                        ? item.description_pl
                                                        : item.description_en,
                                            }}
                                        ></div>
                                        <div className="MainPage_Data--Images">
                                            <a
                                                href="https://www.zkwp.pl"
                                                target="__blank"
                                            >
                                                <img
                                                    src={ZKWP_logo}
                                                    alt="ZKWP logo"
                                                />
                                            </a>
                                            <a
                                                href="https://www.fci.be/en/"
                                                target="__blank"
                                            >
                                                <img
                                                    src={FCI_logo}
                                                    alt="FCI logo"
                                                />
                                            </a>
                                        </div>
                                    </>
                                ))
                        ) : (
                            <div>null</div>
                        )}

                        <div className="MainPage_MainImage">
                            <img src={Mia} alt="" />
                        </div>

                        <div className="MainPage_Data--Quote">
                            {items.length > 0 ? (
                                items
                                    .filter((f) => f.type === "quote")
                                    .map((item) => (
                                        <div className="MainPage_Data--Quote_element">
                                            {item.images.length > 0 ? (
                                                <div className="MainPage-Quote_Image">
                                                    <img
                                                        src={
                                                            item.images[0].path
                                                        }
                                                        alt={""}
                                                    />
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            <div className="MainPage-Quote_Data_Container">
                                                <div className="MainPage-Quote_Data_Container__descCon">
                                                    <div className="MainPage-Quote_Data_Container__img">
                                                        <BiSolidQuoteLeft />
                                                    </div>
                                                    <div className="MainPage-Quote_Data_Container__imgEnd">
                                                        <BiSolidQuoteRight />
                                                    </div>
                                                    <div
                                                        className="MainPage-Quote_Data_Container__descCon--desc"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                lang === "pl"
                                                                    ? item.description_pl
                                                                    : item.description_en,
                                                        }}
                                                    ></div>
                                                    <div className="MainPage-Quote_Data_Container__descCon--author">
                                                        {item.title_pl}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            ) : (
                                <div>null</div>
                            )}
                        </div>
                    </div>

                    <div>
                        <div className="MainPage_Slider">
                            <AiOutlineLeft
                                className="Arrow"
                                onClick={slideLeft}
                            />
                            <div className="slider">
                                <SimpleReactLightbox>
                                    <SRLWrapper
                                        options={{
                                            buttons: {
                                                showDownloadButton: false,
                                            },
                                        }}
                                    >
                                        <div
                                            className="Slider_images"
                                            id="slider"
                                        >
                                            {items
                                                .filter((f) => f.id === 5)
                                                .map((item) =>
                                                    item.images
                                                        .sort(function (a, b) {
                                                            return (
                                                                a.sortID -
                                                                b.sortID
                                                            );
                                                        })
                                                        .map((img) => (
                                                            <img
                                                                src={img.path}
                                                                alt=""
                                                            />
                                                        ))
                                                )}
                                            {/* {Images.map((item) => (
                                                <img src={item.url} alt="" />
                                            ))} */}
                                        </div>
                                    </SRLWrapper>
                                </SimpleReactLightbox>
                            </div>

                            <AiOutlineRight
                                className="Arrow"
                                onClick={slideRight}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }
}
