import React from "react";

import "./Litters.scss";
import MainImage from "../../../Assets/Images/views/Litters/MainImage.jpg";
import { BiSolidQuoteLeft, BiSolidQuoteRight } from "react-icons/bi";
import cookies from "react-cookies";
import quoteImage from "../../../Assets/Images/views/Litters/quoteImage.jpg";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Language } from "../../../utils/Language";
import mia from "../../../Assets/Images/views/Litters/mia.jpg";

export default class Litters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            items: [],
        };
    }

    async componentDidMount() {
        axios
            .get(`https://api.atrustfall.com/litters?_sort=id&_order=ASC`)
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const description_pl = `<p>Jeśli szukasz szczeniaka rasy owczarek australijski, skontaktuj
    się z nami.</p> <p>Nasze szczenięta mogą opuścić hodowlę do nowych domów po ukończeniu 8 tygodnia życia. Przed oddaniem szczenięta są odrobaczone, zaszczepione, zaczipowane i posiadają metrykę urodzenia. Dostają ze sobą podstawową wyprawkę, kopie badań rodziców oraz posiadają imienny certyfikat badań oczu. Zapewniamy również wsparcie i doradztwo dla nowych właścicieli. Serdecznie zapraszamy!</p>`;
        const description_en = `<p>If you are looking for an Australian Shepherd puppy, please contact with us.</p>
    <p>Our puppies can leave our kennel for new homes after they are 8 weeks old. Before leaving our home, the puppies will be dewormed, vaccinated, microchipped and will have a birth certificate/export pedigree. They will receive a basic puppy layette, copies of their parents tests and a personal certificate of eye examination. We also provide support and advice for new owners. You're welcome!</p>`;
        const quote_title = "Josh Bilings";
        const quote_description_pl =
            "Pies jest jedyną istotą na ziemi, która kocha cię bardziej niż siebie samego.";
        const quote_description_en =
            "A dog is the only thing on earth that loves you more than he loves himself.";

        const lang = cookies.load("language");

        const { items, isLoading, error } = this.state;

        if (error) return <span>Error</span>;
        else if (!isLoading) return <span>Loading</span>;
        else
            return (
                <div className="Litters_Container">
                    <div className="Litters_MainImage">
                        <img src={MainImage} alt="" />
                    </div>

                    <div className="Litters_MainPage">
                        <div
                            className="Litters_MainPage--maintext"
                            dangerouslySetInnerHTML={{
                                __html:
                                    lang === "pl"
                                        ? description_pl
                                        : description_en,
                            }}
                        ></div>
                    </div>

                    {items.length > 0 ? (
                        <div className="Litters_Lists">
                            <div className="Litters_Lists--title">
                                {Language.outLitters}:
                            </div>
                            <div>
                                {items.map((item) => (
                                    <div className="Litters_Lists_Element">
                                        <Link to={`/litters/${item.id}`}>
                                            <div className="Litters_Lists_Element--name">
                                                {Language.litter} {item.name}
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        ""
                    )}

                    <div className="Litters_MainImage">
                        <img src={mia} alt="" />
                    </div>

                    <div className="MainPage_Data--Quote">
                        <div className="MainPage_Data--Quote_element">
                            <div className="MainPage-Quote_Image">
                                <img src={quoteImage} alt="" />
                            </div>
                            <div className="MainPage-Quote_Data_Container">
                                <div className="MainPage-Quote_Data_Container__descCon">
                                    <div className="MainPage-Quote_Data_Container__img">
                                        <BiSolidQuoteLeft />
                                    </div>
                                    <div className="MainPage-Quote_Data_Container__imgEnd">
                                        <BiSolidQuoteRight />
                                    </div>
                                    <div
                                        className="MainPage-Quote_Data_Container__descCon--desc"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                lang === "pl"
                                                    ? quote_description_pl
                                                    : quote_description_en,
                                        }}
                                    ></div>
                                    <div className="MainPage-Quote_Data_Container__descCon--author">
                                        {quote_title}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
}
