import React from "react";
import "./header.scss";
import { Link, NavLink } from "react-router-dom";
import cookies from "react-cookies";

import PlFlag from "../../../Assets/Images/flags/pl.svg";
import EnFlag from "../../../Assets/Images/flags/en.svg";
import facebook from "../../../Assets/Images/facebook.svg";
import instagram from "../../../Assets/Images/instagram.svg";
import tiktok from "../../../Assets/Images/tiktok.svg";
import logo from "../../../Assets/Images/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";

import { Language } from "../../../utils/Language";
import axios from "axios";

export default class HeaderComp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDropdownVisible: false,
            isDropdownVisibleDogs: false,
            itemsDogs: [],
            itemsLitters: [],
            isOpen: false,
        };
    }

    async componentDidMount() {
        axios.get("https://api.atrustfall.com/litters").then((res) => {
            this.setState({
                itemsLitters: res.data,
            });
        });

        axios.get("https://api.atrustfall.com/dogs").then((res) => {
            this.setState({
                itemsDogs: res.data,
            });
        });
    }

    changeLanguageToPL = () => {
        cookies.remove("language");
        cookies.save("language", "pl", { path: "/" });
        window.location.reload();
    };
    changeLanguageToEN = () => {
        cookies.remove("language");
        cookies.save("language", "en", { path: "/" });
        window.location.reload();
    };

    changeOpenNav = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };

    render() {
        const { itemsDogs, itemsLitters } = this.state;
        return (
            <>
                <header className="header">
                    <div className="language">
                        <div
                            className="language_item"
                            onClick={this.changeLanguageToPL}
                        >
                            <img src={PlFlag} alt="Poland Flag" />
                        </div>
                        <div
                            className="language_item"
                            onClick={this.changeLanguageToEN}
                        >
                            <img src={EnFlag} alt="England Flag" />
                        </div>
                    </div>
                    <nav className="nav">
                        <div className="nav_element">
                            <div
                                className="nav_element--item"
                                onMouseOver={() =>
                                    this.setState({
                                        isDropdownVisibleDogs: true,
                                    })
                                }
                                onMouseLeave={() =>
                                    this.setState({
                                        isDropdownVisibleDogs: false,
                                    })
                                }
                            >
                                <NavLink
                                    to="/dogs"
                                    className="nav_element--link"
                                >
                                    {Language.dogs}
                                </NavLink>

                                {this.state.isDropdownVisibleDogs &&
                                itemsDogs.length > 0 ? (
                                    <div className="dropdown">
                                        {itemsDogs.filter(
                                            (f) => f.gender === "female"
                                        ).length > 0 ? (
                                            <div className="dropdown--category">
                                                {" "}
                                                {Language.females}{" "}
                                                {itemsDogs
                                                    .filter(
                                                        (f) =>
                                                            f.gender ===
                                                            "female"
                                                    )
                                                    .map((e) => (
                                                        <NavLink
                                                            className="dropdown--link"
                                                            to={{
                                                                pathname: `/dogs/${e.id}`,
                                                                id: e.id,
                                                            }}
                                                            onClick={() =>
                                                                this.setState({
                                                                    isDropdownVisibleDogs: false,
                                                                })
                                                            }
                                                        >
                                                            {e.name}
                                                        </NavLink>
                                                    ))}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {itemsDogs.filter(
                                            (f) => f.gender === "male"
                                        ).length > 0 ? (
                                            <div className="dropdown--category">
                                                {" "}
                                                {Language.males}
                                                {itemsDogs
                                                    .filter(
                                                        (f) =>
                                                            f.gender === "male"
                                                    )
                                                    .map((e) => (
                                                        <NavLink
                                                            className="dropdown--link"
                                                            to={{
                                                                pathname: `/dogs/${e.id}`,
                                                                id: e.id,
                                                            }}
                                                            onClick={() =>
                                                                this.setState({
                                                                    isDropdownVisibleDogs: false,
                                                                })
                                                            }
                                                        >
                                                            {e.name}
                                                        </NavLink>
                                                    ))}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div
                                className="nav_element--item"
                                onMouseOver={() =>
                                    this.setState({ isDropdownVisible: true })
                                }
                                onMouseLeave={() =>
                                    this.setState({ isDropdownVisible: false })
                                }
                            >
                                <NavLink
                                    to="/litters"
                                    className="nav_element--link"
                                >
                                    {Language.litters}
                                </NavLink>

                                {this.state.isDropdownVisible &&
                                itemsLitters.length > 0 ? (
                                    <div className="dropdown">
                                        {itemsLitters.map((e) => (
                                            <NavLink
                                                className="dropdown--link"
                                                to={{
                                                    pathname: `/litters/${e.id}`,
                                                    id: e.id,
                                                }}
                                                onClick={() =>
                                                    this.setState({
                                                        isDropdownVisible: false,
                                                    })
                                                }
                                            >
                                                {Language.litter} {e.name}
                                            </NavLink>
                                        ))}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="nav_element--item">
                                <NavLink
                                    to="/aboutbreed"
                                    className="nav_element--link"
                                >
                                    {Language.aboutBreed}
                                </NavLink>
                            </div>
                        </div>
                        <div className="nav_element">
                            <NavLink to="/" className="nav_element--logo">
                                <img src={logo} alt="Logo" />
                            </NavLink>
                        </div>
                        <div className="nav_element">
                            <NavLink
                                to="/aboutus"
                                className="nav_element--link"
                            >
                                {Language.aboutUs}
                            </NavLink>
                            <NavLink to="/news" className="nav_element--link">
                                {Language.news}
                            </NavLink>
                            <NavLink
                                to="/contact"
                                className="nav_element--link"
                            >
                                {Language.contact}
                            </NavLink>
                        </div>
                    </nav>
                    <div className="media">
                        <div className="media_itemLeft">
                            <a
                                href="https://www.facebook.com/natalia.kurek.54"
                                target="_blank"
                                className="media_itemLeft--link"
                                rel="noreferrer"
                            >
                                <img src={facebook} alt="Facebook logo" />
                            </a>
                            <a
                                href="https://www.instagram.com/a_trustfall/"
                                target="_blank"
                                rel="noreferrer"
                                className="media_itemLeft--link"
                            >
                                <img src={instagram} alt="Instagram logo" />
                            </a>
                        </div>
                        <div className="media_itemRight">
                            <a
                                href="https://www.tiktok.com/@a_trustfall"
                                target="_blank"
                                rel="noreferrer"
                                className="media_itemRight--link"
                            >
                                <img src={tiktok} alt="TikTok logo" />
                            </a>
                        </div>
                    </div>
                </header>

                <header
                    className={`header_mobile ${
                        this.state.isOpen ? "show" : ""
                    }`}
                    id="nav"
                >
                    <div className="header_mobile--logo">
                        <Link to="/" onClick={() => this.changeOpenNav()}>
                            <img src={logo} alt="" />
                        </Link>
                    </div>

                    <div className="header_mobile--nav">
                        <NavLink
                            to="/dogs"
                            onClick={() => this.changeOpenNav()}
                        >
                            {Language.dogs}
                            <div className="dropdown">
                                <div className="dropdown">
                                    {itemsDogs.filter(
                                        (f) => f.gender === "female"
                                    ).length > 0 ? (
                                        <div className="dropdown--category">
                                            {" "}
                                            <div>{Language.females}:</div>{" "}
                                            {itemsDogs
                                                .filter(
                                                    (f) => f.gender === "female"
                                                )
                                                .map((e, index) => (
                                                    <NavLink
                                                        key={index}
                                                        className="dropdown--link"
                                                        to={{
                                                            pathname: `/dogs/${e.id}`,
                                                            id: e.id,
                                                        }}
                                                    >
                                                        {e.name}
                                                    </NavLink>
                                                ))}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {itemsDogs.filter(
                                        (f) => f.gender === "male"
                                    ).length > 0 ? (
                                        <div className="dropdown--category">
                                            {" "}
                                            <div>{Language.males}:</div>
                                            {itemsDogs
                                                .filter(
                                                    (f) => f.gender === "male"
                                                )
                                                .map((e, index) => (
                                                    <NavLink
                                                        key={index}
                                                        className="dropdown--link"
                                                        to={{
                                                            pathname: `/dogs/${e.id}`,
                                                            id: e.id,
                                                        }}
                                                    >
                                                        {e.name}
                                                    </NavLink>
                                                ))}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </NavLink>
                        <NavLink
                            to="/litters"
                            onClick={() => this.changeOpenNav()}
                        >
                            {Language.litters}
                            <div className="dropdown">
                                {itemsLitters.map((e, index) => (
                                    <div className="dropdown" key={index}>
                                        <NavLink
                                            className="dropdown--link"
                                            to={{
                                                pathname: `/litters/${e.id}`,
                                                id: e.id,
                                            }}
                                        >
                                            {Language.litter} {e.name}
                                        </NavLink>
                                    </div>
                                ))}
                            </div>
                        </NavLink>
                        <NavLink
                            to="/aboutbreed"
                            onClick={() => this.changeOpenNav()}
                        >
                            {Language.aboutBreed}
                        </NavLink>
                        <NavLink
                            to="/aboutus"
                            onClick={() => this.changeOpenNav()}
                        >
                            {Language.aboutUs}
                        </NavLink>
                        <NavLink
                            to="/news"
                            onClick={() => this.changeOpenNav()}
                        >
                            {Language.news}
                        </NavLink>
                        <NavLink
                            to="/contact"
                            onClick={() => this.changeOpenNav()}
                        >
                            {Language.contact}
                        </NavLink>
                    </div>

                    <div className="language">
                        <div
                            className="language_item"
                            onClick={this.changeLanguageToPL}
                        >
                            <img src={PlFlag} alt="Poland Flag" />
                        </div>
                        <div
                            className="language_item"
                            onClick={this.changeLanguageToEN}
                        >
                            <img src={EnFlag} alt="England Flag" />
                        </div>
                    </div>

                    <div className="header_mobile--social">
                        <a
                            href="https://www.facebook.com/natalia.kurek.54"
                            target="_blank"
                            className="media_itemLeft--link"
                            rel="noreferrer"
                        >
                            <img src={facebook} alt="Facebook logo" />
                        </a>
                        <a
                            href="https://www.instagram.com/a_trustfall/"
                            target="_blank"
                            rel="noreferrer"
                            className="media_itemLeft--link"
                        >
                            <img src={instagram} alt="Instagram logo" />
                        </a>
                        <a
                            href="https://www.tiktok.com/@a_trustfall"
                            target="_blank"
                            rel="noreferrer"
                            className="media_itemRight--link"
                        >
                            <img src={tiktok} alt="TikTok logo" />
                        </a>
                    </div>
                </header>

                {this.state.isOpen ? (
                    <div
                        id="close"
                        className="header_mobile_close"
                        onClick={() => this.changeOpenNav()}
                    >
                        <IoClose />
                    </div>
                ) : (
                    <div
                        className="header_mobile_hamburger"
                        id="hamburger"
                        onClick={() => this.changeOpenNav()}
                    >
                        <GiHamburgerMenu />
                    </div>
                )}
            </>
        );
    }
}
