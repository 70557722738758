import React from "react";
import { Route, Switch } from "react-router-dom";

import Header from "./Components/Organisms/HeaderComp/HeaderComp";
import Footer from "./Components/Organisms/Footer/Footer";

//import views
import MainPage from "./Components/Views/MainPage/MainPage";
import AboutUs from "./Components/Views/AboutUs/AboutUs";
import News from "./Components/Views/News/News";

import DogsDetails from "./Components/Views/Dogs/DogsDetails";
//import DogsDetails from './Components/Views/Dogs/DogsDetails';

import Litters from "./Components/Views/Litters/Litters";
import LittersDetails from "./Components/Views/Litters/LittersDetails";

import AboutBreed from "./Components/Views/AboutBreed/AboutBreed";
import Contact from "./Components/Views/Contact/Contact";
import GoTopButton from "./Components/Other/GoTopButton";
import Dogs from "./Components/Views/Dogs/Dogs";

function App() {
    return (
        <>
            <Header />

            <Switch>
                <Route path="/" exact component={MainPage} />
                <Route path="/aboutus" exact component={AboutUs} />
                <Route path="/news" exact component={News} />

                {/* Dogs */}
                <Route path="/dogs" exact component={Dogs} />
                <Route path="/dogs/:id" exact component={DogsDetails} />

                {/* Litters*/}
                <Route path="/litters" exact component={Litters} />
                <Route path="/litters/:id" exact component={LittersDetails} />

                <Route path="/aboutbreed" exact component={AboutBreed} />
                <Route path="/contact" exact component={Contact} />
            </Switch>

            <Footer />

            <GoTopButton />
        </>
    );
}

export default App;
