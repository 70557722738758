import React, { Component } from "react";

import "./DogsDetails.scss";
import axios from "axios";

import cookies from "react-cookies";
import { Language } from "../../../utils/Language";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom/cjs/react-router-dom";

export default class DogsDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            items: [],
            litters: [],
        };
    }

    async getData() {
        await axios
            .get(
                `https://api.atrustfall.com/dogs/${this.props.match.params.id}`
            )
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });

        await axios.get(`https://api.atrustfall.com/litters/`).then((res) => {
            this.setState({
                litters: res.data,
            });
        });
    }

    async componentDidMount() {
        this.getData();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            await axios
                .get(
                    "https://api.atrustfall.com/dogs/" +
                        this.props.match.params.id
                )
                .then((res) => {
                    this.setState({
                        isLoading: true,
                        items: res.data,
                    });
                })
                .catch((error) => {
                    this.setState({
                        isLoading: true,
                        error: error.message,
                    });
                });
        }
    }

    render() {
        const slideLeft = () => {
            var slider = document.getElementById("sliderDogs");
            slider.scrollLeft =
                slider.scrollLeft - document.body.clientWidth / 2;
        };

        const slideRight = () => {
            var slider = document.getElementById("sliderDogs");
            slider.scrollLeft =
                slider.scrollLeft + document.body.clientWidth / 2;
        };
        const { items, isLoading, error } = this.state;
        const lang = cookies.load("language");

        if (error) return <div>Error</div>;
        else if (!isLoading) return <div>Loading</div>;
        else {
            return (
                <div className="DogsDetails_Container">
                    <div className="DogsDetails_Container--title">
                        {items.pedigreename}
                    </div>
                    <div className="DogsDetails_Container--birth">
                        {Language.birthDate}: {` `}
                        {new Date(items.birthDate).toISOString().slice(0, 10)}
                    </div>
                    <div>
                        {Language.owner}: {items.owner}
                    </div>
                    <div>
                        {Language.breeder}: {items.breeder}
                    </div>
                    <div className="DogsDetails_Container--litters">
                        {Language.litters}:{" "}
                        {this.state.litters
                            .filter((f) =>
                                Object.values(items.litters).includes(f.id)
                            )
                            .map((e) => (
                                <Link to={`/litters/${e.id}`}>{e.name}</Link>
                            ))}
                        {/* //includes(f.id) */}
                    </div>
                    <div className="DogsDetails_Container--pedigree">
                        <b>{Language.pedigreeLink}</b>:{" "}
                        <a href={items.pedigreeLink} target="__blank">
                            {items.pedigreeLink}
                        </a>
                    </div>
                    <div className="DogsDetails_Container--info">
                        <div className="DogsDetails_Container--info_data">
                            <b>{Language.growth}:</b> {items.growth}cm
                        </div>
                        <div className="DogsDetails_Container--info_data">
                            <b>{Language.weight}:</b> {items.weight}kg
                        </div>
                        <div className="DogsDetails_Container--info_data">
                            <b>{Language.tail}:</b>{" "}
                            {lang === "pl" ? items.tail_pl : items.tail_en}
                        </div>
                        {items.imported.length > 0 &&
                        items.imported !== "undefined" ? (
                            <div className="DogsDetails_Container--info_data">
                                <b>{Language.imported}:</b> {items.imported}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="DogsDetails_Container--data">
                        {items.images.filter((f) => f.field === "mainImg")
                            .length > 0 ? (
                            <div className="DogsDetailsMainimg">
                                <SimpleReactLightbox>
                                    <SRLWrapper
                                        options={{
                                            buttons: {
                                                showDownloadButton: false,
                                            },
                                        }}
                                    >
                                        <img
                                            src={
                                                items.images.filter(
                                                    (f) => f.field === "mainImg"
                                                )[0].path
                                            }
                                            alt=""
                                        />
                                    </SRLWrapper>
                                </SimpleReactLightbox>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="DogsDetailsDesc">
                            <div
                                className="DogsDetailsDesc-description"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        lang === "pl"
                                            ? items.tests_pl
                                            : items.tests_en,
                                }}
                            ></div>
                        </div>
                        {items.images.filter((f) => f.field === "mainImgRight")
                            .length > 0 ? (
                            <div className="DogsDetailsMainimg">
                                <SimpleReactLightbox>
                                    <SRLWrapper
                                        options={{
                                            buttons: {
                                                showDownloadButton: false,
                                            },
                                        }}
                                    >
                                        <img
                                            src={
                                                items.images.filter(
                                                    (f) =>
                                                        f.field ===
                                                        "mainImgRight"
                                                )[0].path
                                            }
                                            alt=""
                                        />
                                    </SRLWrapper>
                                </SimpleReactLightbox>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    {items.tests_pl.length > 0 || items.tests_en.length > 0 ? (
                        <div className="DogsDetails_Container--description">
                            <div className="DogsDetails_Container--description-title">
                                {Language.description}
                            </div>
                            <div
                                className="DogsDetails_Container--description-desc"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        lang === "pl"
                                            ? items.description_pl
                                            : items.description_en,
                                }}
                            ></div>
                        </div>
                    ) : (
                        ""
                    )}
                    {items.images.filter((f) => f.field === "gallery").length >
                    0 ? (
                        <div className="DogsDetails_Container--gallery">
                            <div className="DogsDetails_Container--gallery--title">
                                {Language.gallery}
                            </div>

                            <div className="MainPage_Slider">
                                <AiOutlineLeft
                                    className="Arrow"
                                    onClick={slideLeft}
                                />
                                <div className="slider">
                                    <SimpleReactLightbox>
                                        <SRLWrapper
                                            options={{
                                                buttons: {
                                                    showDownloadButton: false,
                                                },
                                            }}
                                        >
                                            <div
                                                className="Slider_images"
                                                id="sliderDogs"
                                            >
                                                {items.images
                                                    .filter(
                                                        (f) =>
                                                            f.field ===
                                                            "gallery"
                                                    )
                                                    .sort(function (a, b) {
                                                        return (
                                                            a.sortID - b.sortID
                                                        );
                                                    })
                                                    .map((item, index) => (
                                                        <img
                                                            src={item.path}
                                                            alt=""
                                                            key={index}
                                                        />
                                                    ))}
                                            </div>
                                        </SRLWrapper>
                                    </SimpleReactLightbox>
                                </div>

                                <AiOutlineRight
                                    className="Arrow"
                                    onClick={slideRight}
                                />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            );
        }
    }
}
