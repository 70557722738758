import { useRef } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const PuppyGallery = ({ puppy }) => {
    const imagesRef = useRef();

    const clickLeft = () => {
        if (!imagesRef) return;
        imagesRef.current.scrollLeft =
            imagesRef.current.scrollLeft - document.body.clientWidth / 3;
    };

    const clickRight = () => {
        if (!imagesRef) return;
        imagesRef.current.scrollLeft =
            imagesRef.current.scrollLeft + document.body.clientWidth / 3;
    };

    return (
        <div className="puppyContainer__gallery">
            <div className="puppy_Slider sliderDogs">
                <AiOutlineLeft
                    className="Arrow slideLeft"
                    onClick={clickLeft}
                />
                <div className="slider">
                    <SimpleReactLightbox>
                        <SRLWrapper
                            options={{
                                buttons: {
                                    showDownloadButton: false,
                                },
                            }}
                        >
                            <div
                                className="puppy_Slider_Images galleryImages"
                                ref={imagesRef}
                            >
                                {puppy.images
                                    .filter((f) => f.field === "gallery")
                                    .sort(function (a, b) {
                                        return a.sortID - b.sortID;
                                    })
                                    .map((item, index) => (
                                        <img
                                            src={item.path}
                                            alt=""
                                            key={index}
                                        />
                                    ))}
                            </div>
                        </SRLWrapper>
                    </SimpleReactLightbox>
                </div>

                <AiOutlineRight
                    className="Arrow slideRight"
                    onClick={clickRight}
                />
            </div>
        </div>
    );
};

export default PuppyGallery;
