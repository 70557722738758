import React from "react";

import "./AboutBreed.scss";

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

import axios from "axios";
import cookies from "react-cookies";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { BsDot } from "react-icons/bs";
import { Language } from "../../../utils/Language";

export default class AboutBreed extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            items: [],
        };
    }

    async componentDidMount() {
        axios
            .get(`https://api.atrustfall.com/aboutbreed`)
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { items, isLoading, error } = this.state;
        const lang = cookies.load("language");
        const slideLeft = () => {
            const slider = document.getElementById("slider");
            let scrollLeft = slider.scrollLeft;
            slider.scroll({
                top: 0,
                left: scrollLeft - document.body.clientWidth / 2,
                behavior: "smooth",
            });
        };

        const slideRight = () => {
            const slider = document.getElementById("slider");
            let scrollLeft = slider.scrollLeft;
            slider.scroll({
                top: 0,
                left: scrollLeft + document.body.clientWidth / 2,
                behavior: "smooth",
            });
        };

        if (error) return <span>Error</span>;
        else if (!isLoading)
            return (
                <div className="flex w-screen h-full justify-center items-center">
                    Loading
                </div>
            );
        else {
            return (
                <div className="AboutBreed">
                    <div className="AboutBreed--title">{Language.shepherd}</div>
                    {/* API */}
                    <div className="AboutBreed_Data">
                        {items
                            .filter((f) => f.id === 1 || f.id === 8)
                            .map((item) => (
                                <div className="AboutBreed_Container_special">
                                    <div className="AboutBreed_Container_special--descCon">
                                        {item.title_pl !== "null" &&
                                        item.title_pl !== "undefined" &&
                                        item.title_pl !== "" ? (
                                            <div className="AboutBreed_Container_special--title">
                                                {lang === "pl"
                                                    ? item.title_pl
                                                    : item.title_en}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div
                                            className="AboutBreed_Container_special--text"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    lang === "pl"
                                                        ? item.description_pl
                                                        : item.description_en,
                                            }}
                                        ></div>
                                    </div>

                                    <div className="AboutBreed_Container_special--galleryCon">
                                        {item.images.length > 0 ? (
                                            <>
                                                <div className="AboutBreed_Container_special--gallery">
                                                    <SimpleReactLightbox>
                                                        <SRLWrapper>
                                                            {item.images
                                                                .sort(function (
                                                                    a,
                                                                    b
                                                                ) {
                                                                    return (
                                                                        a.sortID -
                                                                        b.sortID
                                                                    );
                                                                })
                                                                .map((img) => (
                                                                    <img
                                                                        src={
                                                                            img.path
                                                                        }
                                                                        alt=""
                                                                    />
                                                                ))}
                                                        </SRLWrapper>
                                                    </SimpleReactLightbox>
                                                </div>
                                                {item.id === 1 ||
                                                item.id === 8 ? (
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                            fontStyle: "italic",
                                                        }}
                                                    >
                                                        {Language.jay}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            ))}

                        {items.length > 0 ? (
                            items
                                .filter(
                                    (f) =>
                                        f.type === "normal" &&
                                        f.id !== 1 &&
                                        f.id !== 8
                                )
                                .map((item) => (
                                    <div className="AboutBreed_Container">
                                        {item.title_pl !== "null" &&
                                        item.title_pl !== "undefined" &&
                                        item.title_pl !== "" ? (
                                            <div className="AboutBreed_Container--title">
                                                {lang === "pl"
                                                    ? item.title_pl
                                                    : item.title_en}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div
                                            className="AboutBreed_Container--text"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    lang === "pl"
                                                        ? item.description_pl
                                                        : item.description_en,
                                            }}
                                        ></div>

                                        <div>
                                            {item.images.length > 0 ? (
                                                <>
                                                    <div>
                                                        <SimpleReactLightbox>
                                                            <SRLWrapper
                                                                id={item.id}
                                                                options={{
                                                                    buttons: {
                                                                        showDownloadButton: false,
                                                                    },
                                                                }}
                                                            >
                                                                <div className="AboutBreed_Container--gallery">
                                                                    {item.images
                                                                        .sort(
                                                                            function (
                                                                                a,
                                                                                b
                                                                            ) {
                                                                                return (
                                                                                    a.sortID -
                                                                                    b.sortID
                                                                                );
                                                                            }
                                                                        )
                                                                        .map(
                                                                            (
                                                                                img
                                                                            ) => (
                                                                                <img
                                                                                    style={{
                                                                                        width: `${
                                                                                            item
                                                                                                .images
                                                                                                .length >
                                                                                            2
                                                                                                ? `calc(100% / ${item.images.length} - 30px)`
                                                                                                : "100%"
                                                                                        }`,
                                                                                    }}
                                                                                    src={
                                                                                        img.path
                                                                                    }
                                                                                    alt=""
                                                                                />
                                                                            )
                                                                        )}
                                                                </div>
                                                            </SRLWrapper>
                                                        </SimpleReactLightbox>
                                                    </div>
                                                    {item.id === 1 ||
                                                    item.id === 8 ? (
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            {Language.jay}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <div>null</div>
                        )}

                        {items.filter((f) => f.type === "fact").length > 0 ? (
                            <div className="AboutBreed__Facts_Container">
                                <div className="AboutBreed__Facts--title">
                                    {Language.didyouknow}
                                </div>
                                <div className="AboutBreed__Facts " id="slider">
                                    {items
                                        .filter((f) => f.type === "fact")
                                        .map((element, index) => (
                                            <div className="AboutBreed__Facts--fact">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            lang === "pl"
                                                                ? element.description_pl
                                                                : element.description_en,
                                                    }}
                                                ></div>
                                            </div>
                                        ))}
                                </div>
                                <div className="bullets">
                                    <div className="Arrow" onClick={slideLeft}>
                                        <AiOutlineLeft />
                                    </div>
                                    <div className="bullets_dots">
                                        {items
                                            .filter((f) => f.type === "fact")
                                            .map((bullet, index) => (
                                                <BsDot />
                                            ))}
                                    </div>
                                    <div className="Arrow" onClick={slideRight}>
                                        <AiOutlineRight />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}

                        {items.filter((f) => f.type === "info").length > 0 ? (
                            items
                                .filter((f) => f.type === "info")
                                .map((item) => (
                                    <div className="AboutBreed_Container">
                                        {item.title_pl !== "null" &&
                                        item.title_pl !== "undefined" &&
                                        item.title_pl !== "" ? (
                                            <div
                                                className="AboutBreed_Container--title"
                                                style={{ textAlign: "left" }}
                                            >
                                                {lang === "pl"
                                                    ? item.title_pl
                                                    : item.title_en}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="AboutBreed_Container--text">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        lang === "pl"
                                                            ? item.description_pl
                                                            : item.description_en,
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <div>null</div>
                        )}
                    </div>
                </div>
            );
        }
    }
}
