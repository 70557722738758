import React from "react";

import "./News.scss";
import axios from "axios";

import cookies from "react-cookies";
import { Language } from "../../../utils/Language";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

export default class News extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            items: [],
        };
    }

    async componentDidMount() {
        await axios
            .get(`https://api.atrustfall.com/news?_sort=added_at&_order=DESC`)
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { items, isLoading, error } = this.state;
        const lang = cookies.load("language");

        if (error) return <div>Error</div>;
        else if (!isLoading) return <div>Loading</div>;
        else {
            return (
                <div className="News_Container">
                    <div className="News_Container--Title">{Language.news}</div>

                    {items.length > 0 ? (
                        <div className="News_Elements">
                            {items.map((item, index) => (
                                <div key={index} className="News_Element">
                                    <div className="News_Element--title">
                                        {lang === "pl"
                                            ? item.title_pl
                                            : item.title_en}
                                    </div>
                                    <div className="News_Element--created">
                                        {Language.created}:{" "}
                                        {new Date(item.added_at)
                                            .toISOString()
                                            .substring(0, 10)}
                                    </div>
                                    <div className="News_Element_Data">
                                        <div
                                            className="News_Element_Data-desc"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    lang === "pl"
                                                        ? item.description_pl
                                                        : item.description_en,
                                            }}
                                        ></div>
                                        {item.images.length > 0 ? (
                                            <div className="News_Element_Data-gallery">
                                                <SimpleReactLightbox>
                                                    <SRLWrapper
                                                        options={{
                                                            buttons: {
                                                                showDownloadButton: false,
                                                            },
                                                        }}
                                                    >
                                                        {item.images
                                                            .sort(function (
                                                                a,
                                                                b
                                                            ) {
                                                                return (
                                                                    a.sortID -
                                                                    b.sortID
                                                                );
                                                            })
                                                            .map(
                                                                (
                                                                    image,
                                                                    index
                                                                ) => (
                                                                    <img
                                                                        src={
                                                                            image.path
                                                                        }
                                                                        alt=""
                                                                        key={
                                                                            index
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                    </SRLWrapper>
                                                </SimpleReactLightbox>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            );
        }
    }
}
